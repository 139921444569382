import React, { useEffect, useState } from 'react'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import InputMask from 'react-input-mask'
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import api from '../../api';
import Alert from '@mui/material/Alert';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Create = (args) => {
    const token = useSelector((state) => state.userSelector.token)
    const [date, setDate] = useState(null)
    const [time, setTime] = useState(null)
    const [status, setStatus] = useState('call')
    const [phone, setPhone] = useState('')
    const [fullName, setFullName] = useState('')
    const [address, setAddress] = useState('')
    const [comment, setComment] = useState('')
    const [notification, setNotification] = useState(null)
    const [expanded, setExpanded] = useState(false)
    const [searchTimeout, setSearchTimeout] = useState(null);

    const handleAccordionChange = () => {
        setExpanded(!expanded);
    };

    const cleanPhoneNumber = (phone) => {
        return phone.replace(/[\s\(\)\+\-_]/g, '');
    };

    const searchAndFillClient = (searchType, value) => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        const timeoutId = setTimeout(() => {
            if (!value) return;

            const searchParams = {
                phone: searchType === 'phone' ? cleanPhoneNumber(value) : '',
                name: searchType === 'name' ? value : '',
                address: searchType === 'address' ? value : ''
            };

            api.searchClient(token, searchParams)
                .then(response => {
                    if (response.data.found) {
                        // Only set values for empty fields
                        if (!phone && searchType !== 'phone') {
                            setPhone(response.data.data.phone || '');
                        }
                        if (!fullName && searchType !== 'name') {
                            setFullName(response.data.data.name || '');
                        }
                        if (!address && searchType !== 'address') {
                            setAddress(response.data.data.address || '');
                        }
                    }
                })
                .catch(error => {
                    console.error('Search failed:', error);
                });
        }, 500);

        setSearchTimeout(timeoutId);
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        setPhone(value);
        searchAndFillClient('phone', value);
    };

    const handleNameChange = (e) => {
        const value = e.target.value;
        setFullName(value);
        searchAndFillClient('name', value);
    };

    const handleAddressChange = (e) => {
        const value = e.target.value;
        setAddress(value);
        searchAndFillClient('address', value);
    };

    useEffect(() => {
        return () => {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }
        };
    }, [searchTimeout]);

    const createTaskFunc = () => {
        const task = {
            date,
            time,
            status,
            phone,
            full_name: fullName,
            address,
            comment
        }

        api.createTask(token, task).then(response => {
            setDate(null)
            setTime(null)
            setStatus('call')
            setPhone('')
            setFullName('')
            setAddress('')
            setComment('')
            setNotification(null)
            args.getTasksFunc()
        }).catch(error => {
            setNotification('Ошибка при создании задачи')
        })
    }

    return (
        <Accordion
            className='getMainCreate getMainCreateTask'
            expanded={expanded}
            onChange={handleAccordionChange}
        >
            <div className='filtrDiv'>
                <Typography
                    className='createClientTypograpyh createTaskTypograpyh'
                    onClick={handleAccordionChange}
                >
                    {expanded ? 'Закрыть' : 'Создать задачу +'}
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className='DemoContainer'>
                        <DatePicker
                            className='datetimepicker'
                            format="DD/MM/YYYY"
                            value={args.dateFrom ? dayjs(args.dateFrom) : null}
                            onChange={(e) => args.setDateFrom(e ? `${e.$y}-${e.$M + 1}-${e.$D}` : null)}
                            label="Дата от"
                        />
                        <DatePicker
                            className='datetimepicker'
                            format="DD/MM/YYYY"
                            value={args.dateTo ? dayjs(args.dateTo) : null}
                            onChange={(e) => args.setDateTo(e ? `${e.$y}-${e.$M + 1}-${e.$D}` : null)}
                            label="Дата до"
                        />
                    </div>
                </LocalizationProvider>
            </div>

            <AccordionDetails className='AccordionDetails'>
                <TableContainer component={Paper}>
                    <Table aria-label="customized table" className='mainTableCreate'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">Дата</StyledTableCell>
                                <StyledTableCell align="left">Время</StyledTableCell>
                                <StyledTableCell align="left">Статус</StyledTableCell>
                                <StyledTableCell align="left">Телефон</StyledTableCell>
                                <StyledTableCell align="left">ФИО</StyledTableCell>
                                <StyledTableCell align="left">Адрес</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='tableBody'>
                            <StyledTableRow className='dataRow'>
                                <StyledTableCell className='datepicker-box-create'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DateTimePicker']}>
                                            <DatePicker
                                                className='datetimepicker'
                                                format="DD/MM/YYYY"
                                                value={date ? dayjs(date) : null}
                                                onChange={(e) => setDate(e ? `${e.$y}-${e.$M + 1}-${e.$D}` : null)}
                                                label="Дата"
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TextField
                                        type="time"
                                        value={time}
                                        onChange={(e) => setTime(e.target.value)}
                                        label="Время"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell className='select-box'>
                                    <Select
                                        className='select'
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                        label="Статус"
                                    >
                                        <MenuItem value="call">Позвонить</MenuItem>
                                        <MenuItem value="waiting">Ожидает</MenuItem>
                                        <MenuItem value="installed">Установлено</MenuItem>
                                        <MenuItem value="serviced">Обслужено</MenuItem>
                                    </Select>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <InputMask
                                        mask="9 (999) 999-99-99"
                                        value={phone}
                                        onChange={handlePhoneChange}
                                    >
                                        {() => <TextField label="Телефон" />}
                                    </InputMask>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TextField
                                        value={fullName}
                                        onChange={handleNameChange}
                                        label="ФИО"
                                    />
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TextField
                                        value={address}
                                        onChange={handleAddressChange}
                                        label="Адрес"
                                    />
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                    <br />
                    <Button disabled={!(phone && fullName)} onClick={createTaskFunc} className='clientCreateButton' variant="contained">
                        Создать
                    </Button>
                    <br />
                    <br />

                    {notification && (
                        <Alert severity="error">
                            {notification}
                        </Alert>
                    )}
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    )
}

export default Create