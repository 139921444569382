import React, { useEffect, useState } from 'react'
import './main.scss'
import MenuComponent from '../../elements/menu/menu'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask'
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Create from './create';
import { Switch, Tooltip } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useDispatch } from "react-redux";
import { setStatistics } from '../../store/features/userSelector';
import dayjs from 'dayjs';
import api from '../../api';


const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 6,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const MainPage = (args) => {
    const token = useSelector((state) => state.userSelector.token)
    const dispatch = useDispatch()
    const [del, setDel] = useState(0)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [searchQuery, setSearchQuery] = useState(null);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [createTask, setCreateTask] = useState(0);
    const [selectedRow, setSelectedRow] = useState(null);

    const getClientsFunc = () => {
        setLoading(true)
        api.getClients(token, args.type, searchQuery, dateFrom, dateTo).then(response => {
            setData(response.data.clients)
            dispatch(setStatistics(response.data.statistics))
        })
        setLoading(false)
    }

    const deleteClientFunc = () => {
        api.deleteClient(token, del).then(response => {
            setDel(0)
            getClientsFunc()
            handleDialogClose()
        })
    }

    const handleDialogOpen = (row) => {
        setSelectedRow(row);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedRow(null);
    };

    const changeElement = (id, key, value) => {
        api.patchClients(token, id, key, value).then(response => {
            console.log(response.data)
        })
    }

    const handleInputChange = (index, id, key, value = null) => {
        changeElement(id, key, value)
        const newData = [...data]
        newData[index][key] = value

        setData(newData)
        
        if (selectedRow && selectedRow.id === id) {
            setSelectedRow({ ...selectedRow, [key]: value })
        }
    }


    function compareDate(inputDate) {
        const today = new Date()
        const dateToCompare = new Date(inputDate)
        if (inputDate == null) {
            return 'rgba(39,39,39,1)'
        }
        const timeDiff = dateToCompare - today
        const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))

        if (daysDiff > 7) {
            return 'rgba(95,255,0,0.2)'
        } else if (daysDiff > 0 && daysDiff <= 7) {
            return 'rgba(255, 251, 0, 0.36)'
        } else {
            return 'rgba(255,0,0,0.2)'
        }
    }
    const createTaskFunc = (id, phone, name, address) => {
        const task = {
            "status": "call",
            "phone": phone,
            "full_name": name,
            "address": address,
        }
        api.createTask(token, task).then(response => {
            setCreateTask(id)
        }).catch(error => {
            setCreateTask(id)
        })
    }
    useEffect(() => {
        if (token) {
            getClientsFunc()
        }

    }, [token, args.type, searchQuery, dateFrom, dateTo])

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <div className='mainDiv'>
                <MenuComponent />

                <Dialog
                    open={del}
                    onClose={() => setDel(0)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Удалить пользователья?"}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setDel(0)}>Отмена</Button>
                        <Button onClick={() => deleteClientFunc(del)} autoFocus>
                            Удалить
                        </Button>
                    </DialogActions>
                </Dialog>

                <Create getClientsFunc={getClientsFunc}
                    type={args.type}
                    setSearchQuery={setSearchQuery}
                    searchQuery={searchQuery}
                    dateFrom={dateFrom}
                    setDateFrom={setDateFrom}
                    dateTo={dateTo}
                    setDateTo={setDateTo}
                    loading={loading}
                />

                <div className={`getMain ${args.type === 'law' && 'getMainLaw'}`}>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table" className='mainTable mainestTable'>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="left" ></StyledTableCell>
                                    <StyledTableCell align="left" >Д. платежа </StyledTableCell>
                                    <StyledTableCell align="left" >Д. установ. </StyledTableCell>
                                    <StyledTableCell align="left" >Договор </StyledTableCell>
                                    {args.type === 'law' &&
                                        <StyledTableCell align="left" >Компания</StyledTableCell>}
                                    <StyledTableCell align="left" >ФИО</StyledTableCell>
                                    <StyledTableCell align="left" >Телефон</StyledTableCell>
                                    <StyledTableCell align="left" >Почта</StyledTableCell>
                                    {args.type !== 'law' &&
                                        <StyledTableCell align="left" >Пол</StyledTableCell>}
                                    <StyledTableCell align="left" >Адрес </StyledTableCell>
                                    <StyledTableCell align="left" >Район </StyledTableCell>
                                    <StyledTableCell align="left" >Трафик</StyledTableCell>
                                    <StyledTableCell align="left" >История </StyledTableCell>
                                    <StyledTableCell align="left" >Суммарно </StyledTableCell>
                                    <StyledTableCell align="left" >Редактировать</StyledTableCell>
                                    <StyledTableCell align="left" >Настройки</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            {loading ?
                                <div className='loadingDataDiv'>
                                    <img src="https://i.gifer.com/ZKZg.gif" alt="" />
                                </div>
                                :
                                <TableBody className='tableBody' >
                                    {data.length === 0 ?
                                        <div className='loadingDataDiv'>
                                            <h3>Пусто</h3>
                                        </div>
                                        :
                                        data.map((row, index) => (
                                            <StyledTableRow key={row.id} className='dataRow' sx={{ background: `linear-gradient(90deg, ${compareDate(row.schedule_time)} 0%, rgba(39,39,39,1) 13%, rgba(39,39,39,1) 100%)` }}>

                                                <StyledTableCell >
                                                    <Tooltip title={row.tarif ? `Оплачен до ${row.tarif}` : 'Не оплачен'} placement="top">
                                                        {/* <h1>{compareDate(row.schedule_time)}</h1> */}

                                                    </Tooltip>
                                                </StyledTableCell >

                                                {args.type !== 'law' ?
                                                    <StyledTableCell className='datepicker-box' >
                                                        <TextField
                                                            format="DD/MM/YYYY"
                                                            value={row.install_time ? new Date(row.schedule_time).toLocaleDateString('ru', { year: "numeric", month: "numeric", day: "numeric" }) : null} id='install_time'
                                                            InputProps={{ readOnly: true }}

                                                        />
                                                    </StyledTableCell >
                                                    :
                                                    <StyledTableCell className='datepicker-box-create' >
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DateTimePicker']}>
                                                                <DatePicker className='datetimepicker'
                                                                    format="DD/MM/YYYY"
                                                                    ampm={false}
                                                                    value={dayjs(row.schedule_time)}
                                                                    id='schedule_time'
                                                                    disabled={row.edit ? false : true}
                                                                    onChange={(e) => handleInputChange(index, row.id, 'schedule_time', `${e.$y}-${e.$M + 1}-${e.$D}`)}
                                                                    label="Дата платежа"
                                                                />
                                                            </DemoContainer>
                                                        </LocalizationProvider>

                                                    </StyledTableCell >
                                                }
                                                {args.type !== 'law' ?
                                                    <StyledTableCell className='datepicker-box' >

                                                        <TextField
                                                            format="DD/MM/YYYY"
                                                            value={row.install_time ? new Date(row.install_time).toLocaleDateString('ru', { year: "numeric", month: "numeric", day: "numeric" }) : null} id='install_time'
                                                            InputProps={{ readOnly: true }}

                                                        />

                                                    </StyledTableCell >
                                                    :
                                                    <StyledTableCell className='datepicker-box-create' >
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DateTimePicker']}>
                                                                <DatePicker className='datetimepicker'
                                                                    format="DD/MM/YYYY"
                                                                    ampm={false}
                                                                    value={dayjs(row.install_time)}
                                                                    id='install_time'
                                                                    disabled={row.edit ? false : true}
                                                                    onChange={(e) => handleInputChange(index, row.id, 'install_time', `${e.$y}-${e.$M + 1}-${e.$D}`)}
                                                                    label="Дата установки" />
                                                            </DemoContainer>
                                                        </LocalizationProvider>

                                                    </StyledTableCell >
                                                }

                                                <StyledTableCell className='dogovor-box'>
                                                    <InputMask
                                                        mask={`${row.type === 'so5' ? 'СО5' : row.type === 'so8' ? 'СО8' : 'СО10'}№*********`}
                                                        formatChars={{ '*': '[0-9/]' }}
                                                        value={row.user}
                                                        InputProps={{ readOnly: !row.edit }}
                                                        onChange={(e) => handleInputChange(index, row.id, 'user', e.target.value)}

                                                    >
                                                        {() => <TextField onfo InputProps={{ readOnly: !row.edit }} id="user-basic" label="Договор" variant="outlined" />}
                                                    </InputMask>
                                                </StyledTableCell >
                                                {args.type === 'law' &&
                                                    <StyledTableCell className='company-box'><TextField value={row.company} InputProps={{ readOnly: !row.edit }} onChange={(e) => handleInputChange(index, row.id, 'company', e.target.value)} onfo className='company-input' label="Компания" variant="outlined" /></StyledTableCell >}

                                                <StyledTableCell className='fio-box'><TextField value={row.name} InputProps={{ readOnly: !row.edit }} onChange={(e) => handleInputChange(index, row.id, 'name', e.target.value)} onfo className='fio-input' label="ФИО" variant="outlined" /></StyledTableCell >
                                                <StyledTableCell className='phone-box'>
                                                    <InputMask

                                                        mask="9 (999) 999-99-99"
                                                        value={row.number}
                                                        disabled={row.edit ? false : true}
                                                        onChange={(e) => handleInputChange(index, row.id, 'number', e.target.value)}
                                                    >
                                                        {() => <TextField id="number-basic" label="Телефон" variant="outlined" />}
                                                    </InputMask>
                                                </StyledTableCell >
                                                <StyledTableCell className='email-box'><TextField value={row.email} InputProps={{ readOnly: !row.edit }} onChange={(e) => handleInputChange(index, row.id, 'email', e.target.value)} onfo className='email-input' label="Почта" variant="outlined" /></StyledTableCell >
                                                {args.type !== 'law' &&
                                                    <StyledTableCell className='gender-box' >
                                                        <Select

                                                            className='gender-select select'
                                                            value={row.gender}
                                                            disabled={row.edit ? false : true}
                                                            label="Пол"
                                                            onChange={(e) => handleInputChange(index, row.id, 'gender', e.target.value)}
                                                        >
                                                            <MenuItem value={true}>муж</MenuItem>
                                                            <MenuItem value={false}>жен</MenuItem>
                                                        </Select>
                                                    </StyledTableCell >}
                                                <StyledTableCell className={`adress-box ${args.type === 'law' && 'adress-box-law'}`}><TextField value={row.address} InputProps={{ readOnly: !row.edit }} onChange={(e) => handleInputChange(index, row.id, 'address', e.target.value)} onfo className="adress-basic" label="Адрес" variant="outlined" /></StyledTableCell >
                                                <StyledTableCell className='select-box' >
                                                    <Select
                                                        id="demo-location-select"
                                                        className='location-select select'
                                                        value={row.location}
                                                        disabled={row.edit ? false : true}
                                                        label="Район"
                                                        onChange={(e) => handleInputChange(index, row.id, 'location', e.target.value)}
                                                    >
                                                        <MenuItem value={'Московский '}>Московский</MenuItem>
                                                        <MenuItem value={'Железнодорожный'}>Октябрьский</MenuItem>
                                                        <MenuItem value={'Советский'}>Советский </MenuItem>
                                                        <MenuItem value={'Октябрьский'}>Железнодорожный</MenuItem>
                                                        <MenuItem value={'Ряз. Область '}>Ряз. область</MenuItem>
                                                    </Select>
                                                </StyledTableCell >
                                                <StyledTableCell className='select-box' >
                                                    <Select
                                                        className='refferal-select select'
                                                        value={row.referral}
                                                        disabled={row.edit ? false : true}
                                                        label="Трафик"
                                                        onChange={(e) => handleInputChange(index, row.id, 'referral', e.target.value)}
                                                    >
                                                        <MenuItem value={'Рекомендация'}>Рекомендация</MenuItem>
                                                        <MenuItem value={'Рассылка СМС'}>Рассылка СМС</MenuItem>
                                                        <MenuItem value={'ВКонтакте'}>ВКонтакте</MenuItem>
                                                        <MenuItem value={'Расклейка'}>Расклейка</MenuItem>
                                                        <MenuItem value={'Лифты'}>Лифты</MenuItem>
                                                        <MenuItem value={'Интернет'}>Интернет</MenuItem>
                                                        <MenuItem value={'ТВ'}>ТВ</MenuItem>
                                                        <MenuItem value={'Радио'}>Радио</MenuItem>
                                                        <MenuItem value={'Билборды'}>Билборды</MenuItem>
                                                        <MenuItem value={'Звонки'}>Звонки</MenuItem>
                                                        <MenuItem value={'Спонсор'}>Спонсор</MenuItem>

                                                    </Select>

                                                </StyledTableCell >
                                                <StyledTableCell className='textarea-box' >
                                                    <textarea value={row.history} disabled={row.edit ? false : true} onChange={(e) => handleInputChange(index, row.id, 'history', e.target.value)} id="history-basic" label="История" >
                                                    </textarea>
                                                </StyledTableCell >
                                                <StyledTableCell >
                                                    <h3>
                                                        <input type="number" className='summary-input' value={row.summary} min={0} disabled={row.edit ? false : true} onChange={(e) => handleInputChange(index, row.id, 'summary', e.target.value ? e.target.value : 0)} />
                                                        руб</h3>
                                                </StyledTableCell >
                                                <StyledTableCell align='center'>
                                                    <Switch defaultChecked={row.edit} onChange={(e) => handleInputChange(index, row.id, 'edit', !row.edit)} />
                                                </StyledTableCell >


                                                <StyledTableCell align='center'>
                                                    <Button onClick={() => handleDialogOpen(row)} variant="text">⚙️</Button>
                                                </StyledTableCell>
                                                <Dialog open={dialogOpen} onClose={handleDialogClose}>
                                                    <DialogTitle>Выберите действие</DialogTitle>
                                                    <DialogContent >
                                                        {args.type !== 'law' &&
                                                            <Button fullWidth variant="text">
                                                                Увидомление
                                                                <Switch fullWidth checked={selectedRow?.notification} onChange={(e) => handleInputChange(index, selectedRow.id, 'notification', !selectedRow.notification)} />
                                                            </Button>}
                                                        {createTask === selectedRow?.id ?
                                                            <Button fullWidth variant="text">
                                                                Задача создана ✅
                                                            </Button>
                                                            :
                                                            <Button fullWidth onClick={() => createTaskFunc(selectedRow.id, selectedRow.number, selectedRow.name, selectedRow.address)} variant="text">
                                                                Добавить в задачи 📝
                                                            </Button>
                                                        }
                                                        <Button fullWidth onClick={() => setDel(selectedRow.id)} variant="text">
                                                            Удалить 🗑️
                                                        </Button>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={handleDialogClose} color="primary">
                                                            Закрыть
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>

                                            </StyledTableRow>
                                        ))}
                                    <div className='sliderDiv'>
                                        {/* {pageInfo.previous ?
                                            <p onClick={() => getByUrl(pageInfo.previous)}>&#129068;</p>
                                            :
                                            <p></p>
                                        }
                                        {pageInfo.current_page}/{pageInfo.total_pages}
                                        {pageInfo.next ?
                                            <p onClick={() => getByUrl(pageInfo.next)}>&#129070;</p>
                                            :
                                            <p></p>
                                        } */}

                                    </div>
                                </TableBody>
                            }
                        </Table>

                    </TableContainer>

                </div>
            </div>
        </ThemeProvider >
    )
}

export default MainPage