import React, { useEffect, useState } from 'react';
import './task.scss';
import MenuComponent from '../../elements/menu/menu';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import api from '../../api';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Switch } from '@mui/material';
import Create from './create';
import FormControlLabel from '@mui/material/FormControlLabel';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 6,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Task = () => {
    const token = useSelector((state) => state.userSelector.token);
    const [del, setDel] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [maintenanceDate, setMaintenanceDate] = useState(null);
    const [maintenanceStatus, setMaintenanceStatus] = useState('');
    const [maintenanceFilters, setMaintenanceFilters] = useState([]);



    const getTasksFunc = () => {
        setLoading(true);
        api.getTasks(token, null, dateFrom, dateTo).then((response) => {
            setData(response.data);
        });
        setLoading(false);
    };

    const changeElement = (id, key, value) => {
        api.updateTask(token, id, key, value).then((response) => {
            console.log(response.data);
        });
    };

    const handleInputChange = (index, id, key, value = null) => {
        changeElement(id, key, value);
        const newData = [...data];
        newData[index][key] = value;

        setData(newData);

        if (selectedRow && selectedRow.id === id) {
            setSelectedRow({ ...selectedRow, [key]: value });
        }

        if (key === 'status' && value === 'serviced') {
            setStatusDialogOpen(true);
        }
    };

    const deleteTaskFunc = () => {
        api.deleteTask(token, del).then((response) => {
            setDel(false);
            getTasksFunc();
            // handleDialogClose()
        });
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'call':
                return 'rgba(255,0,0,0.2)'; // Red
            case 'waiting':
                return 'rgba(0,0,255,0.2)'; // Blue
            case 'installed':
            case 'serviced':
                return 'rgba(0,255,0,0.2)'; // Green
            default:
                return 'rgba(39,39,39,1)';
        }
    }


    const filterHandleChange = (event) => {
        const value = event.target.value;
        setMaintenanceFilters((prev) =>
            event.target.checked
                ? [...prev, value]
                : prev.filter((filter) => filter !== value)
        );
    };


    const maintenanceUpdate = (id) => {
        console.log(maintenanceDate);
        console.log(maintenanceStatus);
        console.log(maintenanceFilters);

        const data = {
            "date": maintenanceDate,
            "status": maintenanceStatus,
            "filters": maintenanceFilters,
        }
        console.log(data);

        api.maintenanceUpdateFromTask(token, id, data).then((response) => {
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })

        setStatusDialogOpen(false)
        setMaintenanceFilters([])
    }

    useEffect(() => {
        if (token) {
            getTasksFunc();
        }
    }, [token, dateFrom, dateTo]);

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <div className="mainDiv mainDivTask">
                <MenuComponent />
                <Create getTasksFunc={getTasksFunc} setDateFrom={setDateFrom} setDateTo={setDateTo} />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Дата</StyledTableCell>
                                <StyledTableCell>Время</StyledTableCell>
                                <StyledTableCell>Статус</StyledTableCell>
                                <StyledTableCell>Телефон</StyledTableCell>
                                <StyledTableCell>ФИО</StyledTableCell>
                                <StyledTableCell>Адрес</StyledTableCell>
                                <StyledTableCell>Комментарий</StyledTableCell>
                                <StyledTableCell>Редактировать</StyledTableCell>
                                <StyledTableCell>Удалить</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <div className="loadingDataDiv">
                                    <img src="https://i.gifer.com/ZKZg.gif" alt="" />
                                </div>
                            ) : (
                                data.map((row, index) => (
                                    <StyledTableRow
                                        key={row.id}
                                        sx={{
                                            background: `linear-gradient(90deg, ${getStatusColor(row.status)} 0%, rgba(39,39,39,1) 25%, rgba(39,39,39,1) 100%)`,
                                        }}
                                    >
                                        <StyledTableCell className="taskInputBox">
                                            <TextField
                                                type="date"
                                                value={row.date}
                                                disabled={row.edit ? false : true}
                                                className="taskInput"
                                                onChange={(e) => handleInputChange(index, row.id, 'date', e.target.value)}
                                            />
                                        </StyledTableCell>

                                        <StyledTableCell className="taskInputBox">
                                            <TextField
                                                type="time"
                                                value={row.time ? row.time.slice(0, 5) : row.time} // Обрезаем секунды
                                                className="taskInput"
                                                disabled={row.edit ? false : true}
                                                onChange={(e) => {
                                                    const timeWithoutSeconds = e.target.value.slice(0, 5); // Убираем секунды
                                                    handleInputChange(index, row.id, 'time', timeWithoutSeconds);
                                                }}
                                                inputProps={{
                                                    step: 60, // Запретить ввод секунд
                                                }}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell className="taskInputBox">
                                            <Select
                                                value={row.status}
                                                className="taskInput select-box-task"
                                                disabled={row.edit ? false : true}
                                                onChange={(e) => handleInputChange(index, row.id, 'status', e.target.value)}
                                            >
                                                <MenuItem value="call">Позвонить</MenuItem>
                                                <MenuItem value="waiting">Ожидает</MenuItem>
                                                <MenuItem value="installed">Установлено</MenuItem>
                                                <MenuItem value="serviced">Обслужено</MenuItem>
                                            </Select>
                                        </StyledTableCell>
                                        <StyledTableCell className="taskInputBox">
                                            <InputMask
                                                mask="9 (999) 999-99-99"
                                                value={row.phone}
                                                disabled={row.edit ? false : true}
                                                className="taskInput"
                                                onChange={(e) => handleInputChange(index, row.id, 'phone', e.target.value)}
                                            >
                                                {() => <TextField disabled={row.edit ? false : true} label="Телефон" />}
                                            </InputMask>
                                        </StyledTableCell>
                                        <StyledTableCell className="taskInputBox">
                                            <TextField
                                                value={row.full_name}
                                                className="taskInput"
                                                disabled={row.edit ? false : true}
                                                onChange={(e) => handleInputChange(index, row.id, 'full_name', e.target.value)}
                                                label="ФИО"
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell className="taskInputBox">
                                            <TextField
                                                value={row.address}
                                                className="taskInput"
                                                disabled={row.edit ? false : true}
                                                onChange={(e) => handleInputChange(index, row.id, 'address', e.target.value)}
                                                label="Адрес"
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell className="taskInputBox">
                                            <textarea
                                                value={row.comment}
                                                disabled={row.edit ? false : true}
                                                onChange={(e) => handleInputChange(index, row.id, 'comment', e.target.value)}
                                                id="history-basic"
                                                label="Комментарий"
                                            ></textarea>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" className='switchBox'>
                                            <Switch className='switchButton' defaultChecked={row.edit} onChange={(e) => handleInputChange(index, row.id, 'edit', e.target.checked)} />
                                        </StyledTableCell>
                                        <StyledTableCell className="taskInputBox">
                                            <Button onClick={() => setDel(row.id)}>Удалить🗑️</Button>
                                            <Dialog
                                                open={del}
                                                onClose={() => setDel(0)}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle id="alert-dialog-title">{"Удалить задачу?"}</DialogTitle>
                                                <DialogActions>
                                                    <Button onClick={() => setDel(0)}>Отмена</Button>
                                                    <Button onClick={() => deleteTaskFunc(del)} autoFocus>
                                                        Удалить
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            {/* maintenance change dialog */}
                                            <Dialog
                                                open={statusDialogOpen}
                                                onClose={() => { setStatusDialogOpen(false); setMaintenanceFilters([]) }}
                                                aria-labelledby="status-dialog-title"
                                                aria-describedby="status-dialog-description"
                                            >
                                                <DialogTitle id="status-dialog-title">{"Статус изменен на 'Обслужено'"}</DialogTitle>
                                                <DialogContent className="status-dialog-content">
                                                    <TextField
                                                        type="date"
                                                        onChange={(e) => setMaintenanceDate(e.target.value)}
                                                    />
                                                    <Select
                                                        defaultValue="-"
                                                        onChange={(e) => setMaintenanceStatus(e.target.value)}
                                                    >
                                                        <MenuItem value="-">Выберите статус</MenuItem>
                                                        <MenuItem value="served">Обслужено</MenuItem>
                                                        <MenuItem value="not_required">Не требуется</MenuItem>
                                                    </Select>
                                                    <div className="checkbox-group">
                                                        {[...Array(10)].map((_, index) => {
                                                            const filterValue = `filter_${index + 1}`;
                                                            return (
                                                                <FormControlLabel
                                                                    key={filterValue}
                                                                    value={filterValue}
                                                                    control={
                                                                        <Checkbox
                                                                            checked={maintenanceFilters.includes(filterValue)}
                                                                            onChange={filterHandleChange}
                                                                        />
                                                                    }
                                                                    label={`Фильтр ${index + 1}`}
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={() => maintenanceUpdate(row.id)} autoFocus>
                                                        Сохранить
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </ThemeProvider >
    );
};

export default Task;