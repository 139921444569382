import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import api from '../../api';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';


const Create = () => {
    const token = useSelector((state) => state.userSelector.token);
    const [maintenanceDate, setMaintenanceDate] = useState(null);
    const [status, setStatus] = useState('');
    const [history, setHistory] = useState('');
    const [client, setClient] = useState('');
    const [notification, setNotification] = useState(null);
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = () => {
        setExpanded(!expanded);
    };

    const createMaintenance = () => {
        const maintenance = {
            maintenance_date: maintenanceDate,
            status,
            history,
            client,
        };

        api.postMaintenanceItem(token, maintenance).then(response => {
            setMaintenanceDate(null);
            setStatus('');
            setHistory('');
            setClient('');
            setNotification('Maintenance created successfully');
        }).catch(error => {
            setNotification('Error creating maintenance');
        });
    };

    return (
        <Accordion
            className='getMainCreate getMainCreateMaintenance'
            expanded={expanded}
            onChange={handleAccordionChange}
        >
            <div className='filtrDiv'>
                <Typography
                    className='createClientTypograpyh createMaintenanceTypograpyh'
                    onClick={handleAccordionChange}
                >
                    {expanded ? 'Закрыть' : 'Создать обслуживание +'}
                </Typography>
            </div>

            <AccordionDetails className='AccordionDetails'>
                <TableContainer component={Paper}>
                    <Table aria-label="customized table" className='mainTableCreate'>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Дата обслуживания</TableCell>
                                <TableCell align="left">Статус</TableCell>
                                <TableCell align="left">История</TableCell>
                                <TableCell align="left">Клиент</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            format="DD/MM/YYYY"
                                            value={maintenanceDate ? dayjs(maintenanceDate) : null}
                                            onChange={(e) => setMaintenanceDate(e ? `${e.$y}-${e.$M + 1}-${e.$D}` : null)}
                                            label="Дата обслуживания"
                                        />
                                    </LocalizationProvider>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                        label="Статус"
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        value={history}
                                        onChange={(e) => setHistory(e.target.value)}
                                        label="История"
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        value={client}
                                        onChange={(e) => setClient(e.target.value)}
                                        label="Клиент"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <Button onClick={createMaintenance} className='clientCreateButton' variant="contained">
                    Создать
                </Button>
                <br />
                <br />
                {notification && (
                    <Alert severity="error">
                        {notification}
                    </Alert>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default Create;