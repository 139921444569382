import React, { useState } from 'react'
import api from '../../api';
import Box from '@mui/material/Box';
import { useDispatch } from "react-redux";
import { setAuthorized } from '../../store/features/userSelector';
import { Alert, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './login.scss'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [alert, setAlert] = useState(false)

  const loginFunc = () => {
    setDisabled(true)
    if (login && password) {
      setAlert(null)
      api.loginUser(login, password)
        .then(response => {
          dispatch(setAuthorized(response.data.token))
          navigate('/')
        })

        .catch(error => {
          setAlert({ "text": error.response.data.message, "status": "error" })
        });
    } else {
      setAlert({ 'status': 'warning', 'text': 'Пожалуйста заполните все поля.' })
    }
    setDisabled(false)
  }

  return (
    <div className='loginDiv'>
      <Box className='loginSection'>
        <p>Авторизация</p>
        <TextField disabled={disabled} value={login} onChange={(e) => setLogin(e.target.value)} label="Логин" variant="outlined" />
        <TextField disabled={disabled} value={password} type='password' onChange={(e) => setPassword(e.target.value)} label="Пароль" variant="outlined" />
        <Button onClick={loginFunc} disabled={disabled} variant="contained">Войти</Button>
        {alert &&
          <Alert variant="outlined" severity={alert.status}>
            {alert.text}
          </Alert>
        }
      </Box>

    </div>
  )
}

export default Login