import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { setUnauthorized } from '../../store/features/userSelector';
import { Link } from 'react-router-dom';
import './menu.scss';

const MenuComponent = () => {
    const dispatch = useDispatch();
    const statistics = useSelector((state) => state.userSelector.statistics)
    const [anchorEl, setAnchorEl] = useState(null);

    const logout = () => {
        dispatch(setUnauthorized());
        window.location.reload();
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar component="nav">
            <Toolbar>
                <Button
                    sx={{ color: '#fff' }}
                    onClick={handleMenuOpen}
                >
                    База клиентов
                </Button>
                <Link to={'/tasks'}>
                    <Button
                        sx={{ color: '#fff' }}
                    >
                        Задачи
                    </Button>
                </Link>
                <Link to='/service'>
                    <Button
                        sx={{ color: '#fff' }}
                    >
                        Обслуживание
                    </Button>
                </Link>
                <Link to='/inventory'>
                    <Button
                        sx={{ color: '#fff' }}
                    >
                        Склад
                    </Button>
                </Link>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <Link onClick={handleMenuClose} to='/so5'>
                        <Button sx={{ color: '#fff' }}>
                            СО№5
                        </Button>
                    </Link>
                    <Link onClick={handleMenuClose} to='/so8'>
                        <Button sx={{ color: '#fff' }}>
                            СО№8
                        </Button>
                    </Link>
                    <Link onClick={handleMenuClose} to='/so10'>
                        <Button sx={{ color: '#fff' }}>
                            СО№10
                        </Button>
                    </Link>
                    <Link onClick={handleMenuClose} to='/law'>
                        <Button sx={{ color: '#fff' }}>
                            Юр. лица
                        </Button>
                    </Link>
                </Menu>
                <div className='logoutButton'>
                    <p>{statistics && `Станции: ${statistics.total_count}`}</p>
                    <p>{statistics && `Доход: ${statistics.toyal_monthly_income} руб`} </p>
                    <Button onClick={logout} variant="text">Выйти</Button>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default MenuComponent;
