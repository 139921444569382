import React, { useEffect, useState } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MenuComponent from '../../elements/menu/menu';
import api from '../../api';
import { useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import './inventory.scss'

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const Inventory = () => {
    const token = useSelector((state) => state.userSelector.token)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [searchQuery, setSearchQuery] = useState('')
    const [statistics, setStatistics] = useState({ total_purchase_price: 0, total_market_price: 0 })
    const [dialogOpen, setDialogOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [createDialogOpen, setCreateDialogOpen] = useState(false)
    const [newItem, setNewItem] = useState({ name: '', quantity: '', purchase_price: '', market_price: '', warehouse_number: '', created_at: null, updated_at: null, comment: '', image: null })
    const [editedItem, setEditedItem] = useState(null)
    const [validationError, setValidationError] = useState('')
    const [highlightedFields, setHighlightedFields] = useState({})
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [itemToDelete, setItemToDelete] = useState(null)

    const getInventoryItems = () => {
        setLoading(true)
        api.getInventoryItems(token, searchQuery).then(response => {
            setData(response.data)
            setLoading(false)
        })
    }

    const getInventoryStatistics = () => {
        api.getInventoryStatistics(token).then(response => {
            setStatistics(response.data)
        })
    }

    const handleInputChange = (key, value) => {
        setEditedItem({ ...editedItem, [key]: value })
    }

    const handleDialogOpen = (item) => {
        setSelectedItem(item)
        setEditedItem(item)
        setDialogOpen(true)
    }

    const handleDialogClose = () => {
        setDialogOpen(false)
        setSelectedItem(null)
        setEditedItem(null)
    }

    const handleDeleteDialogOpen = (item) => {
        setItemToDelete(item)
        setDeleteDialogOpen(true)
    }

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false)
        setItemToDelete(null)
    }

    const saveChanges = () => {
        const formData = new FormData()
        formData.append('name', editedItem.name)
        formData.append('quantity', editedItem.quantity)
        formData.append('purchase_price', editedItem.purchase_price)
        formData.append('market_price', editedItem.market_price)
        formData.append('warehouse_number', editedItem.warehouse_number)
        formData.append('created_at', editedItem.created_at)
        formData.append('updated_at', editedItem.updated_at)
        formData.append('comment', editedItem.comment)
        if (editedItem.image) {
            formData.append('photo', editedItem.image)
        }


        api.patchInventoryItem(token, editedItem.id, formData).then(response => {
            const newData = data.map(item => item.id === editedItem.id ? editedItem : item)
            setData(newData)
            handleDialogClose()
            getInventoryStatistics()
        })
    }

    const deleteInventoryItem = () => {
        api.deleteInventoryItem(token, itemToDelete.id).then(response => {
            getInventoryItems()
            getInventoryStatistics()
            handleDeleteDialogClose()
        })
    }

    const downloadInventory = () => {
        api.downloadInventory(token)
    }

    const handleCreateDialogOpen = () => {
        setCreateDialogOpen(true)
    }

    const handleCreateDialogClose = () => {
        setCreateDialogOpen(false)
        setNewItem({ name: '', quantity: '', purchase_price: '', market_price: '', warehouse_number: '', created_at: null, updated_at: null, comment: '', image: null })
        setValidationError('')
        setHighlightedFields({})
    }

    const handleNewItemChange = (key, value) => {
        setNewItem({ ...newItem, [key]: value })
        setHighlightedFields({ ...highlightedFields, [key]: false })
    }

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setNewItem({ ...newItem, image: reader.result });
            setHighlightedFields({ ...highlightedFields, image: false })
        };
        reader.readAsDataURL(file);
    };

    const createInventoryItem = () => {
        // Validate all required fields
        const requiredFields = ['name', 'quantity', 'purchase_price', 'market_price', 'warehouse_number', 'created_at', 'updated_at', 'image']
        const newHighlightedFields = {}
        let allFieldsFilled = true

        requiredFields.forEach(field => {
            if (!newItem[field]) {
                newHighlightedFields[field] = true
                allFieldsFilled = false
            }
        })

        if (!allFieldsFilled) {
            setValidationError('Заполните все необходимые поля.')
            setHighlightedFields(newHighlightedFields)
            return
        }

        const formData = new FormData()
        formData.append('name', newItem.name)
        formData.append('quantity', newItem.quantity)
        formData.append('purchase_price', newItem.purchase_price)
        formData.append('market_price', newItem.market_price)
        formData.append('warehouse_number', newItem.warehouse_number)
        formData.append('created_at', newItem.created_at)
        formData.append('updated_at', newItem.updated_at)
        formData.append('comment', newItem.comment)
        formData.append('photo', newItem.image)

        api.postInventoryItem(token, formData).then(response => {
            getInventoryItems()
            getInventoryStatistics()
            handleCreateDialogClose()
        })
    }

    useEffect(() => {
        if (token) {
            getInventoryItems()
            getInventoryStatistics()
            getInventoryStatistics()
        }
    }, [token, searchQuery])

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <div className='mainDiv'>
                <MenuComponent />
                <div className='inventoryDiv'>
                    <div className='bashDiv'>

                        <Typography
                            className='statisticButton'
                            onClick={handleCreateDialogOpen}
                        >
                            Создать карточку +
                        </Typography>
                        <Typography
                            className='statisticButton'
                            onClick={downloadInventory}
                        >
                            Скачать склад
                        </Typography>

                        <div className="inventoryStatisticDiv">                            
                            <p>Стоимость закупки: {statistics.total_purchase_price} руб</p>
                            <p>Рыночная стоимость: {statistics.total_market_price} руб</p>
                        </div>

                    </div>
                    <div className='inventoryes'>
                        {data.length > 0 ? data.map((item, index) => (
                            <Card key={item.id} className='inventoryItem'>
                                <CardContent className='inventoryItemContent'>
                                    <img src={`data:image/jpeg;base64,${item.photo}`} alt="" height={'140px'} />
                                    <Typography gutterBottom variant='h5' component='div'>
                                        {item.name}
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <span>Стоимость закупки:</span> {item.purchase_price} руб
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <span>Рыночная стоимость:</span> {item.market_price} руб
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <span>Количество:</span> {item.quantity}
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <span>Номер склада:</span> {item.warehouse_number}
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <span>Создано:</span> {item.created_at}
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        <span>Обновлено :</span> {item.updated_at}
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        {item.comment}
                                    </Typography>
                                    <div className='controllButtons'>

                                        <Button size='small' onClick={() => handleDialogOpen(item)}>Изменить</Button>
                                        <Button size='small' onClick={() => handleDeleteDialogOpen(item)}>Удалить</Button>
                                    </div>
                                </CardContent>
                            </Card>
                        ))
                        :
                        <h3>Пусто</h3>
                    }
                    </div>
                </div>
            </div>
            <Dialog open={dialogOpen} onClose={handleDialogClose} className='inventoryDialog'>
                <DialogTitle>Изменить карточку</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin='dense'
                        label='Название'
                        type='text'
                        fullWidth
                        variant='standard'
                        value={editedItem?.name || ''}
                        onChange={(e) => handleInputChange('name', e.target.value)}
                    />

                    <TextField
                        margin='dense'
                        label='Количество'
                        type='number'
                        fullWidth
                        variant='standard'
                        value={editedItem?.quantity || ''}
                        onChange={(e) => handleInputChange('quantity', e.target.value)}
                    />
                    <TextField
                        margin='dense'
                        label='Стоимость закупки'
                        type='number'
                        fullWidth
                        variant='standard'
                        value={editedItem?.market_price || ''}
                        onChange={(e) => handleInputChange('market_price', e.target.value)}
                    />
                    <TextField
                        margin='dense'
                        label='Рыночная стоимость'
                        type='number'
                        fullWidth
                        variant='standard'
                        value={editedItem?.purchase_price || ''}
                        onChange={(e) => handleInputChange('purchase_price', e.target.value)}
                    />
                    <TextField
                        margin='dense'
                        label='Номер склада'
                        type='number'
                        fullWidth
                        variant='standard'
                        value={editedItem?.warehouse_number || ''}
                        onChange={(e) => handleInputChange('warehouse_number', e.target.value)}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className='DemoContainer'>
                            <DatePicker
                                className='datetimepicker'
                                format="DD/MM/YYYY"
                                value={editedItem?.created_at ? dayjs(editedItem.created_at) : null}
                                onChange={(e) => handleInputChange('created_at', e ? `${e.$y}-${e.$M + 1}-${e.$D}` : null)}
                                label="Создано"
                            />
                            <DatePicker
                                className='datetimepicker'
                                format="DD/MM/YYYY"
                                value={editedItem?.updated_at ? dayjs(editedItem.updated_at) : null}
                                onChange={(e) => handleInputChange('updated_at', e ? `${e.$y}-${e.$M + 1}-${e.$D}` : null)}
                                label="Обновлено"
                            />
                        </div>
                    </LocalizationProvider>
                    <TextField
                        margin='dense'
                        label='Комментарий'
                        type='text'
                        fullWidth
                        variant='standard'
                        value={editedItem?.comment || ''}
                        onChange={(e) => handleInputChange('comment', e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Отмена</Button>
                    <Button onClick={saveChanges}>Сохранить</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={createDialogOpen} onClose={handleCreateDialogClose} className='inventoryDialog'>
                <DialogTitle>Создать кртаочку</DialogTitle>
                <DialogContent>
                    <div className='imageUploadDiv'>
                        {newItem.image && <img src={newItem.image} alt="" />}
                        <label htmlFor='raised-button-file'>
                            <Button variant='contained' component='span' color={`${highlightedFields.image ? 'error' : 'primary'}`}>
                                {newItem.image ? "Выбрать другое изображение" : "Загрузить изображение"}
                            </Button>
                        </label>
                        <input
                            accept='image/*'
                            style={{ display: 'none' }}
                            id='raised-button-file'
                            type='file'
                            onChange={handleImageUpload}
                        />

                    </div>
                    <TextField
                        autoFocus
                        margin='dense'
                        label='Название'
                        type='text'
                        fullWidth
                        variant='standard'
                        value={newItem.name}
                        onChange={(e) => handleNewItemChange('name', e.target.value)}
                        error={highlightedFields.name}
                        helperText={highlightedFields.name ? 'Обязательное поле' : ''}
                    />
                    <TextField
                        margin='dense'
                        label='Количество'
                        type='number'
                        fullWidth
                        variant='standard'
                        value={newItem.quantity}
                        onChange={(e) => handleNewItemChange('quantity', e.target.value)}
                        error={highlightedFields.quantity}
                        helperText={highlightedFields.quantity ? 'Обязательное поле' : ''}
                    />
                    <TextField
                        margin='dense'
                        label='Стоимость закупки'
                        type='number'
                        fullWidth
                        variant='standard'
                        value={newItem.purchase_price}
                        onChange={(e) => handleNewItemChange('purchase_price', e.target.value)}
                        error={highlightedFields.purchase_price}
                        helperText={highlightedFields.purchase_price ? 'Обязательное поле' : ''}
                    />
                    <TextField
                        margin='dense'
                        label='Рыночная стоимость'
                        type='number'
                        fullWidth
                        variant='standard'
                        value={newItem.market_price}
                        onChange={(e) => handleNewItemChange('market_price', e.target.value)}
                        error={highlightedFields.market_price}
                        helperText={highlightedFields.market_price ? 'Обязательное поле' : ''}
                    />
                    <TextField
                        margin='dense'
                        label='Номер склада'
                        type='number'
                        fullWidth
                        variant='standard'
                        value={newItem.warehouse_number}
                        onChange={(e) => handleNewItemChange('warehouse_number', e.target.value)}
                        error={highlightedFields.warehouse_number}
                        helperText={highlightedFields.warehouse_number ? 'Обязательное поле' : ''}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className='DemoContainer'>
                            <DatePicker
                                className='datetimepicker'
                                format="DD/MM/YYYY"
                                value={newItem.created_at ? dayjs(newItem.created_at) : null}
                                onChange={(e) => handleNewItemChange('created_at', e ? `${e.$y}-${e.$M + 1}-${e.$D}` : null)}
                                label="Создано"
                                slotProps={{
                                    textField: {
                                        error: highlightedFields.created_at,
                                        helperText: highlightedFields.created_at ? 'Обязательное поле' : ''
                                    }
                                }}
                            />
                            <DatePicker
                                className='datetimepicker'
                                format="DD/MM/YYYY"
                                value={newItem.updated_at ? dayjs(newItem.updated_at) : null}
                                onChange={(e) => handleNewItemChange('updated_at', e ? `${e.$y}-${e.$M + 1}-${e.$D}` : null)}
                                label="Обновлено"
                                slotProps={{
                                    textField: {
                                        error: highlightedFields.updated_at,
                                        helperText: highlightedFields.updated_at ? 'Обязательное поле' : ''
                                    }
                                }}
                            />
                        </div>
                    </LocalizationProvider>
                    <TextField
                        margin='dense'
                        label='Комментарий'
                        type='text'
                        fullWidth
                        variant='standard'
                        value={newItem.comment}
                        onChange={(e) => handleNewItemChange('comment', e.target.value)}
                    />
                    {validationError && (
                        <Typography color="error" variant="body2">
                            {validationError}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateDialogClose}>Отмена</Button>
                    <Button onClick={createInventoryItem}>Сохранить</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                <DialogTitle>Подтверждение удаления</DialogTitle>
                <DialogContent>
                    <Typography>Вы уверены, что хотите удалить карточку?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>Отмена</Button>
                    <Button onClick={deleteInventoryItem} >Удалить</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}

export default Inventory