import React, { useEffect, useState } from 'react';
import MenuComponent from '../../elements/menu/menu';
import api from '../../api';
import { useSelector } from 'react-redux';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Switch } from '@mui/material';
import dayjs from 'dayjs';
import Create from './create';
import './service.scss';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {

    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const getStatusColor = (nextMaintenanceDate) => {
    const today = dayjs();
    const nextDate = dayjs(nextMaintenanceDate);
    const diffDays = nextDate.diff(today, 'day');

    if (diffDays <= 7) {
        return 'rgba(255,0,0,0.2)';
    } else if (diffDays <= 30) {
        return 'rgba(255, 174, 0, 0.23)';
    } else if (diffDays <= 90) {
        return 'rgba(218, 214, 0, 0.54)';
    } else {
        return 'rgba(0,255,0,0.2)';
    }
};

const Service = () => {
    const token = useSelector((state) => state.userSelector.token);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    const getMaintenanceItems = () => {
        setLoading(true);
        api.getMaintenanceItems(token).then(response => {
            setData(response.data);
            setLoading(false);
        });
    };

    const handleInputChange = (index, id, key, value) => {
        const newData = [...data];
        newData[index][key] = value;
        if (key === 'maintenance_date') {
            newData[index].next_maintenance_date = dayjs(value).add(6, 'month').format('YYYY-MM-DD');
        }
        setData(newData);
        saveChanges(id, { [key]: value });
    };

    const saveChanges = (id, updatedFields) => {
        api.patchMaintenanceItem(token, id, updatedFields).then(response => {
            // getMaintenanceItems();
        });
    };

    const handleDeleteDialogOpen = (id) => {
        setItemToDelete(id);
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
        setItemToDelete(null);
    };

    const deleteMaintenanceItem = () => {
        api.deleteMaintenanceItem(token, itemToDelete).then(response => {
            getMaintenanceItems();
            handleDeleteDialogClose();
        });
    };

    const addToTasks = (item, index) => {
        const taskData = {
            status: 'call',
            full_name: item.client.name,
            address: item.client.address,
            phone: item.client.number,
            comment: item.history,
            client: item.client.id,
            maintenance: item.id,
        }

        api.createTask(token, taskData).then(response => {
            const newData = [...data];
            newData[index].taskAdded = true;
            setData(newData);
        });
    };

    useEffect(() => {
        if (token) {
            getMaintenanceItems();
        }
    }, [token]);

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <div className='mainDiv'>
                <MenuComponent />
                {/* <Create/> */}
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell className='taskInputBox'>Тип</StyledTableCell>
                                <StyledTableCell className='taskInputBox'>Пользователь</StyledTableCell>
                                <StyledTableCell className='taskInputBox'>Дата установки</StyledTableCell>
                                <StyledTableCell className='taskInputBox'>Дата обслуживания</StyledTableCell>
                                <StyledTableCell className='taskInputBox'>Дата следующего обслуживания</StyledTableCell>
                                <StyledTableCell className='taskInputBox'>История</StyledTableCell>
                                <StyledTableCell className='taskInputBox'>Добавить в задачи</StyledTableCell>
                                <StyledTableCell className='taskInputBox'>Редактировать</StyledTableCell>
                                <StyledTableCell className='taskInputBox'>Удалить</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <StyledTableCell className='taskInputBox' colSpan={8} align="center">
                                        Загрузка...
                                    </StyledTableCell>
                                </TableRow>
                            ) : (
                                data.map((row, index) => (
                                    <StyledTableRow
                                        key={row.id}
                                        sx={{
                                            background: `linear-gradient(90deg, ${getStatusColor(row.next_maintenance_date)} 0%, rgba(39,39,39,1) 25%, rgba(39,39,39,1) 100%)`,
                                        }}
                                    >
                                        <StyledTableCell className='taskInputBox' >{row.client.type.replace('so', ' со')}</StyledTableCell>
                                        <StyledTableCell className='taskInputBox' >{row.client.user.split("№")[1]}</StyledTableCell>
                                        <StyledTableCell className='taskInputBox' >{dayjs(row.client.install_time).format('DD.MM.YYYY')}</StyledTableCell>
                                        <StyledTableCell className='taskInputBox' >
                                            <TextField
                                                disabled={row.edit ? false : true}
                                                type="date"
                                                value={row.maintenance_date}
                                                onChange={(e) => handleInputChange(index, row.id, "maintenance_date", e.target.value)}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell className='taskInputBox' >
                                            <TextField
                                                className='disabledButWhite'
                                                type="date"
                                                value={row.next_maintenance_date}
                                                disabled
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell className='taskInputBox' >
                                            <textarea value={row.history}
                                                onChange={(e) => handleInputChange(index, row.id, "history", e.target.value)}
                                                id="history-basic"
                                                disabled={row.edit ? false : true}
                                                label="Комментарий" >

                                            </textarea>
                                        </StyledTableCell>
                                        <StyledTableCell className='taskInputBox' >
                                            <Button
                                                onClick={() => addToTasks(row, index)}
                                                disabled={row.taskAdded}
                                            >
                                                {row.taskAdded ? 'Добавленно ✅' : 'Добавить в задачи'}
                                            </Button>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" className='switchBox'>
                                            <Switch className='switchButton' defaultChecked={row.edit} onChange={(e) => handleInputChange(index, row.id, 'edit', e.target.checked)} />
                                        </StyledTableCell>
                                        <StyledTableCell className='taskInputBox' >
                                            <Button onClick={() => handleDeleteDialogOpen(row.id)}>Удалить</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleDeleteDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Удалить запись об обслуживании?"}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={handleDeleteDialogClose}>Отмена</Button>
                        <Button onClick={deleteMaintenanceItem} autoFocus>
                            Удалить
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </ThemeProvider >
    );
};

export default Service;