import { ApiUrl } from './env'
import axios from "axios"


export default class api {
    static async loginUser(username, password) {
        const data = await axios.post(ApiUrl + '/api/login', {
            username: username,
            password: password
        })
        console.log(data);
        return data
    }
    static async authCheck(token) {
        const data = await axios.post(ApiUrl + '/api/auth',
            {},
            {
                headers: {
                    'AUTHORIZATION': `Token ${token}`,
                }

            })
        return data
    }
    static async getClients(token, type, search, date_from, date_to) {
        const data = await axios.get(`${ApiUrl}/api/clients`,
            {
                params: {
                    type: type,
                    search: search,
                    date_from: date_from,
                    date_to: date_to,
                },
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }
    static async postClient(token, client) {
        const data = await axios.post(`${ApiUrl}/api/clients`,
            client,
            {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }
    static async patchClients(token, id, key, value) {
        const data = await axios.patch(`${ApiUrl}/api/clients/${id}`,
            { [key]: value },
            {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }
    static async deleteClient(token, id) {
        const data = await axios.delete(`${ApiUrl}/api/clients/${id}`,
            {
                headers: {
                    'Authorization': `Token ${token}`,
                }

            })
        return data
    }
    static async downloadClients(token, type, search, date_from, date_to) {
        const response = await axios.post(
            `${ApiUrl}/api/clients/download`,
            {
                type: type,
                search: search,
                date_from: date_from,
                date_to: date_to,
            },
            {
                headers: {
                    'Authorization': `Token ${token}`,
                },
                responseType: 'blob', // Important for file download
            }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'clients.xlsx'); // or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    static async getTasks(token, status, date_from, date_to) {
        const data = await axios.get(`${ApiUrl}/api/tasks`,
            {
                params: {
                    status: status,
                    date_from: date_from,
                    date_to: date_to,
                },
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }

    static async createTask(token, task) {
        const data = await axios.post(`${ApiUrl}/api/tasks`,
            task,
            {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }

    static async updateTask(token, id, key, value) {
        const data = await axios.patch(`${ApiUrl}/api/tasks/${id}`,
            { [key]: value },
            {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }

    static async deleteTask(token, id) {
        const data = await axios.delete(`${ApiUrl}/api/tasks/${id}`,
            {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }

    static async searchClient(token, { phone = '', name = '', address = '' }) {
        const data = await axios.get(`${ApiUrl}/api/client/search`,
            {
                params: {
                    phone,
                    name,
                    address
                },
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }

    static async getInventoryItems(token, search) {
        const data = await axios.get(`${ApiUrl}/api/inventory`,
            {
                params: {
                    search: search,
                },
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }

    static async postInventoryItem(token, item) {
        const data = await axios.post(`${ApiUrl}/api/inventory`,
            item,
            {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }

    static async patchInventoryItem(token, id, item) {
        const data = await axios.patch(`${ApiUrl}/api/inventory/${id}`,
            item,
            {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }

    static async deleteInventoryItem(token, id) {
        const data = await axios.delete(`${ApiUrl}/api/inventory/${id}`,
            {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }

    static async getInventoryStatistics(token) {
        const data = await axios.get(`${ApiUrl}/api/inventory/statistics`,
            {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }

    static async downloadInventory(token) {
        const response = await axios.get(
            `${ApiUrl}/api/inventory/download`,
            {
                headers: {
                    'Authorization': `Token ${token}`,
                },
                responseType: 'blob', // Important for file download
            }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'inventory.xlsx'); // or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    static async getMaintenanceItems(token) {
        const data = await axios.get(`${ApiUrl}/api/maintenance`,
            {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }

    static async postMaintenanceItem(token, item) {
        const data = await axios.post(`${ApiUrl}/api/maintenance`,
            item,
            {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }

    static async patchMaintenanceItem(token, id, item) {
        const data = await axios.patch(`${ApiUrl}/api/maintenance/${id}`,
            item,
            {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }

    static async deleteMaintenanceItem(token, id) {
        const data = await axios.delete(`${ApiUrl}/api/maintenance/${id}`,
            {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }
    static async maintenanceUpdateFromTask(token, id, update) {
        const data = await axios.post(`${ApiUrl}/api/maintenance/update_from_task/${id}`,
            { ...update },
            {
                headers: {
                    'Authorization': `Token ${token}`,
                }
            })
        return data
    }
}