import { createSlice } from '@reduxjs/toolkit'

export const userSelectorSlice = createSlice({
    name: 'userSelector',
    initialState: {
        authorized: false,
        token: '',
        statistics: null // Add statistics to initial state
    },
    reducers: {
        setAuthorized: (state, action) => {
            state.authorized = true
            state.token = action.payload
            localStorage.setItem('token', action.payload)
        },
        setUnauthorized: (state) => {
            state.token = null
            state.statistics = null // Clear statistics on logout
            localStorage.removeItem('token')
        },
        // Add new reducer for statistics
        setStatistics: (state, action) => {
            state.statistics = action.payload
        }
    },
})

export const {
    setAuthorized,
    setUnauthorized,
    setStatistics, // Export new action
} = userSelectorSlice.actions

export default userSelectorSlice.reducer