import React, { useEffect, useState } from 'react'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import InputMask from 'react-input-mask'
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import api from '../../api';
import Alert from '@mui/material/Alert';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const Create = (args) => {
    const token = useSelector((state) => state.userSelector.token)
    const [install_time, setInstall_time] = useState(null)
    const [schedule_time, setSchedule_time] = useState(null)
    const [type, setType] = useState(null)
    const [user, setUser] = useState(null)
    const [userMask, setUserMask] = useState('СО*№*********')
    const [name, setName] = useState(null)
    const [number, setNumber] = useState(null)
    const [email, setEmail] = useState(null)
    const [gender, setGender] = useState(true)
    const [location, setLocation] = useState(null)
    const [address, setAddress] = useState(null)
    const [referral, setReferral] = useState(null)
    const [notification, setNotification] = useState(null)
    const [userTarifValidation, setUserTarifValidation] = useState(false)
    const statistics = useSelector((state) => state.userSelector.statistics)
    const [expanded, setExpanded] = useState(false); // Add this state
    const [dialogOpen, setDialogOpen] = useState(false);


    // Add this function to handle accordion expansion
    const handleAccordionChange = () => {
        setExpanded(!expanded);
    };

    const handleDownload = () => {
        api.downloadClients(
            token,
            args.type,
            args.searchQuery,
            args.dateFrom,
            args.dateTo
        );
    };

    const createClientFunc = () => {

        let client = {
            "install_time": install_time,
            "type": type,
            "user": user,
            "name": name,
            "number": number,
            "email": email,
            "location": location,
            "gender": gender,
            "address": address,
            "referral": referral
        }
        if (args.type === "law") {
            client['schedule_time'] = schedule_time
            client['law'] = true
        }
        api.postClient(token, client).then(response => {
            setInstall_time(null)
            setSchedule_time(null)
            setType(null)
            setUser('')
            setName('')
            setNumber('')
            setEmail('')
            setGender(true)
            setLocation('')
            setAddress('')
            setReferral('')
            setNotification(null)
            args.getClientsFunc()


        }).catch(error => {
            if (error.response.status == 400) {
                setNotification('Договор уже существует')

            }
        })
    }

    useEffect(() => {
        if (user) {
            let prefix = user.split('№')[0]
            if (prefix === 'СО5') {
                setUserTarifValidation(false)
                setType('so5')
            } else if (prefix === 'СО8') {
                setUserTarifValidation(false)
                setType('so8')
            } else if (prefix === 'СО10') {
                setUserTarifValidation(false)
                setType('so10')
            }
            else {
                setUserTarifValidation(true)
            }
        }
    }, [user])


    const userCodeHandler = (e) => {
        let prefix = e.target.value.split('№')[0]
        let value = e.target.value.replace(" ", "/")
        if (prefix === 'СО1' || prefix === 'СО10' || prefix === 'СО1_') {
            setUserMask('СО**№*********')
            setUser(value)
        }
        else {
            setUserMask('СО*№*********')
            setUser(value)
        }
    }


    return (
        <Accordion
            className='getMainCreate'
            expanded={expanded}
            onChange={handleAccordionChange}
        >
            <div className='filtrDiv' >
                <Typography
                    className='createClientTypograpyh'
                    onClick={handleAccordionChange}
                >
                    {`Создать ${args.type === 'law' ? 'юр. лицо' : "клиента"} +`}
                </Typography>
                <TextField className='searchInput' value={args.searchQuery} onChange={(e) => args.setSearchQuery(e.target.value)} onfo id="name-basic" label="Поиск" variant="outlined" />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker']}>
                        <DatePicker className='datetimepicker' format="DD/MM/YYYY" ampm={false} error={false} value={args.dateFrom ? dayjs(args.dateFrom) : null} id='install_time' onChange={(e) => args.setDateFrom(e ? `${e.$y}-${e.$M + 1}-${e.$D}` : null)} label="Дата от" />
                    </DemoContainer>
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker']}>
                        <DatePicker className='datetimepicker' format="DD/MM/YYYY" ampm={false} error={false} value={args.dateTo ? dayjs(args.dateTo) : null} id='install_time' onChange={(e) => args.setDateTo(e ? `${e.$y}-${e.$M + 1}-${e.$D}` : null)} label="Дата до" />
                    </DemoContainer>
                </LocalizationProvider>
                <Typography
                    className='statisticButton'
                    onClick={е => setDialogOpen(true)}
                >
                    Статистика
                </Typography>
                <Typography
                    className='statisticButton'
                    onClick={handleDownload}
                >
                    Скачать базу
                </Typography>

                <p>
                    Станции: {statistics && statistics.client_count}
                </p>
                <p>
                    Доход: {statistics && statistics.monthly_income} руб
                </p>



                <Dialog open={dialogOpen} className='statisticDialog' onClose={е => setDialogOpen(false)} aria-labelledby="form-dialog-title">
                    <DialogTitle>Статистика</DialogTitle>
                    <DialogContent >
                        <div className="statisticDiv">
                            {!args.statistics &&
                                <div className="statisticDiv">
                                    <div className="statBlock">
                                        <h4>Трафик:</h4>
                                        {statistics && statistics.referral && Object.entries(statistics.referral).map(([key, value]) => (
                                            <p key={key}>{key}: {value}</p>
                                        ))}
                                    </div>

                                    <div className="statBlock">
                                        <h4>Районы:</h4>
                                        {statistics && statistics.location && Object.entries(statistics.location).map(([key, value]) => (
                                            <p key={key}>{key}: {value}</p>
                                        ))}
                                    </div>

                                    <div className="statBlock">
                                        <h4>Пол:</h4>
                                        {statistics && statistics.gender && Object.entries(statistics.gender).map(([key, value]) => (
                                            <p >{key === 'True' ? 'Мужской' : 'Женский'}: {value}</p>
                                        ))}
                                    </div>
                                </div>}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={е => setDialogOpen(false)} color="primary">
                            Закрыть
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >


            <AccordionDetails className='AccordionDetails'>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700, }} aria-label="customized table" className='mainTableCreate'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left" >Дата установки </StyledTableCell>
                                {args.type === 'law' &&
                                    <StyledTableCell align="left" >Дата платежа </StyledTableCell>

                                }
                                <StyledTableCell align="left" >Договор </StyledTableCell>
                                {args.type !== 'law' &&
                                    <StyledTableCell align="left" >ФИО</StyledTableCell>}
                                <StyledTableCell align="left" >Телефон</StyledTableCell>
                                <StyledTableCell align="left" >Почта</StyledTableCell>
                                {args.type !== 'law' &&
                                    <StyledTableCell align="left" >Пол</StyledTableCell>}
                                <StyledTableCell align="left" >Район</StyledTableCell>
                                <StyledTableCell align="left" >Адрес </StyledTableCell>
                                <StyledTableCell align="left" >Трафик</StyledTableCell>

                            </TableRow>
                        </TableHead>

                        <TableBody className='tableBody' >
                            <StyledTableRow className='dataRow' >
                                <StyledTableCell className='datepicker-box-create' >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DateTimePicker']}>
                                            <DatePicker className='datetimepicker' format="DD/MM/YYYY" ampm={false} value={dayjs(install_time)} id='install_time' onChange={(e) => setInstall_time(e ? `${e.$y}-${e.$M + 1}-${e.$D}` : null)} label="Дата установки" />
                                        </DemoContainer>
                                    </LocalizationProvider>

                                </StyledTableCell >
                                {args.type === 'law' &&
                                    <StyledTableCell className='datepicker-box-create' >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DateTimePicker']}>
                                                <DatePicker className='datetimepicker' format="DD/MM/YYYY" ampm={false} value={dayjs(schedule_time)} id='schedule_time' onChange={(e) => setSchedule_time(e ? `${e.$y}-${e.$M + 1}-${e.$D}` : null)} label="Дата платежа" />
                                            </DemoContainer>
                                        </LocalizationProvider>

                                    </StyledTableCell >}



                                <StyledTableCell >

                                    <InputMask
                                        mask={userMask}
                                        formatChars={{ '*': '[0-9/ ]' }}
                                        value={user}
                                        onChange={(e) => userCodeHandler(e)}
                                    >
                                        {() => <TextField onfo
                                            error={userTarifValidation}
                                            id="user-basic"
                                            label="Договор"
                                            variant="outlined" />}
                                    </InputMask>

                                </StyledTableCell >
                                {args.type !== 'law' &&
                                    <StyledTableCell ><TextField value={name} onChange={(e) => setName(e.target.value)} onfo id="name-basic" label="ФИО" variant="outlined" /></StyledTableCell >
                                }
                                <StyledTableCell >
                                    <InputMask
                                        mask="9 (999) 999-99-99"
                                        value={number}
                                        onChange={(e) => setNumber(e.target.value)}
                                    >
                                        {() => <TextField
                                            id="number-basic"
                                            label="Телефон"
                                            variant="outlined"
                                        />}
                                    </InputMask>
                                </StyledTableCell >

                                <StyledTableCell ><TextField value={email} onChange={(e) => setEmail(e.target.value)} onfo id="email-basic" label="Почта" variant="outlined" /></StyledTableCell >
                                {args.type !== 'law' &&
                                    <StyledTableCell className='select-box' >
                                        <Select
                                            className='gender-select select'
                                            value={gender}
                                            label="Пол"
                                            onChange={(e) => setGender(e.target.value)}
                                        >
                                            <MenuItem value={true}>муж</MenuItem>
                                            <MenuItem value={false}>жен</MenuItem>
                                        </Select>
                                    </StyledTableCell >
                                }
                                <StyledTableCell className='select-box' >
                                    <Select
                                        className='location-select select'
                                        value={location}
                                        label="Район"
                                        onChange={(e) => setLocation(e.target.value)}
                                    >
                                        <MenuItem value={'Московский '}>Московский </MenuItem>
                                        <MenuItem value={'Железнодорожный'}>Железнодорожный</MenuItem>
                                        <MenuItem value={'Советский'}>Советский</MenuItem>
                                        <MenuItem value={'Октябрьский'}>Октябрьский</MenuItem>
                                        <MenuItem value={'Ряз. Область '}>Ряз. Область </MenuItem>
                                    </Select>
                                </StyledTableCell >

                                <StyledTableCell ><TextField value={address} onChange={(e) => setAddress(e.target.value)} onfo id="adress-basic" label="Адрес" variant="outlined" /></StyledTableCell >

                                <StyledTableCell className='select-box' >
                                    <Select
                                        id="demo-refferal-select"
                                        className='refferal-select select'
                                        value={referral}
                                        label="Трафик"
                                        onChange={(e) => setReferral(e.target.value)}
                                    >
                                        <MenuItem value={'Рекомендация'}>Рекомендация</MenuItem>
                                        <MenuItem value={'Рассылка СМС'}>Рассылка СМС</MenuItem>
                                        <MenuItem value={'ВКонтакте'}>ВКонтакте</MenuItem>
                                        <MenuItem value={'Расклейка'}>Расклейка</MenuItem>
                                        <MenuItem value={'Сарафан'}>Сарафан</MenuItem>
                                        <MenuItem value={'Лифты'}>Лифты</MenuItem>
                                        <MenuItem value={'Интернет'}>Интернет</MenuItem>
                                        <MenuItem value={'ТВ'}>ТВ</MenuItem>
                                        <MenuItem value={'Радио'}>Радио</MenuItem>
                                        <MenuItem value={'Билборды'}>Билборды</MenuItem>
                                        <MenuItem value={'Звонки'}>Звонки</MenuItem>
                                        <MenuItem value={'Спонсор'}>Спонсор</MenuItem>

                                    </Select>

                                </StyledTableCell >
                            </StyledTableRow>
                        </TableBody>
                    </Table>

                    <br />
                    <Button onClick={createClientFunc} disabled={(userTarifValidation && user && install_time)} className='clientCreateButton' variant="contained">Создать</Button>
                    <br /><br />

                    {notification &&
                        <Alert severity="error">
                            {notification}
                        </Alert>}
                </TableContainer>
            </AccordionDetails>
        </Accordion >

    )
}

export default Create