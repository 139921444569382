import { BrowserRouter, Routes, Route } from 'react-router-dom';
import api from './api';
import MainPage from './pages/main/main';
import Login from './pages/login/login';
import Task from './pages/task/task';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setAuthorized, setUnauthorized } from './store/features/userSelector';
import './App.css';
import Inventory from './pages/inventory/inventory';
import Service from './pages/service/service';

function App() {
  const isAuthorized = useSelector((state) => state.userSelector.authorized)
  const [load, setLoad] = useState(false)
  const dispatch = useDispatch()


  const authCheckFunc = (token) => {
    api.authCheck(token).then(response => {

    }).catch(error => {
      if (error.message === 'Network Error') {
        window.location.reload()
      }
      else if (error.response.status === 403) {
        dispatch(setUnauthorized())
      }
    })
  }


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(setAuthorized(token))
    }
    authCheckFunc(token)

    setLoad(true)
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        {load ?
          isAuthorized ?
            <>
              <Route path="/" element={<MainPage type={'so10'} />} />
              <Route path="/so5" element={<MainPage type={'so5'} />} />
              <Route path="/so8" element={<MainPage type={'so8'} />} />
              <Route path="/so10" element={<MainPage type={'so10'} />} />
              <Route path="/law" element={<MainPage type={'law'} />} />
              <Route path="/tasks" element={<Task />} />
              <Route path="/inventory" element={<Inventory />} />
              <Route path="/service" element={<Service />} />
            </>
            :
            <>
              <Route path="*" element={<Login />} />
            </>
          :
          <></>
        }

        <Route path="*" element={<>404</>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
