// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logoutButton {
  position: absolute;
  right: 10px;
  display: flex;
  gap: 30px;
}
.logoutButton p {
  color: gray;
}
.logoutButton button {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/elements/menu/menu.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,aAAA;EACA,SAAA;AACJ;AACI;EACI,WAAA;AACR;AAII;EACI,YAAA;AAFR","sourcesContent":[".logoutButton {\n    position: absolute;\n    right: 10px;\n    display: flex;\n    gap: 30px;\n\n    p {\n        color: gray;\n    }\n\n    ;\n\n    button {\n        color: white;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
